<template>
  <div class="panel">
    <div class="acod-head">
      <h5 class="acod-title">
        <a
          v-if="!link"
          data-toggle="collapse"
          :href="'#' + id"
          class="collapsed"
          ><i :class="icon_class"></i> {{ label }}
        </a>
        <router-link class="direct" v-if="link" :to="link"
          ><i :class="icon_class"></i> {{ label }}</router-link
        >
      </h5>
    </div>
    <div :id="id" class="acod-body collapse">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon_class: String,
    label: String,
    id: String,
    link: String,
  },
});
</script>

<style lang="scss" scoped>
@import "../../../public/css/variables.scss";
.acod-head {
  .acod-title {
    text-transform: uppercase;
    a {
      color: black;
      &.router-link-exact-active,
      &[data-toggle="collapse"] {
        &:not(.collapsed) {
          color: $primary;
        }
      }
      &.direct {
        &::after {
          display: none;
        }
      }
    }
  }
}
.acod-body {
  margin-left: 22px;
  margin-bottom: 10px;
}
</style>
